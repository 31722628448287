
declare global {
  interface Window {
    initMap: () => void;
  }
}
window.initMap = initMap;
function initMap(): void {
  // The location of Uluru
  const uluru = { lat: 33.29719074244352, lng: -111.9596241 };
  // The map, centered at Uluru
  const map = new globalThis.google.maps.Map(
    document.querySelector('#map') as HTMLElement,
    {
      zoom: 13,
      center: uluru
    }
  );

  // The marker, positioned at Uluru
  const marker = new globalThis.google.maps.Marker({
    position: uluru,
    map: map
  });
}

import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import MasterLayout from '@/components/MasterLayout.vue';
import '@/assets/fonts.styl';

@Component({ components: { MasterLayout } })
export default class Home extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  metaInfo(): any {
    return {
      meta: [
        {
          name: 'description',
          content:
            'Just 1 Label is a digital label printing company. We provide online label printing services for companies in various industries nationwide. Register for free today.'
        }
      ],
      title: 'Digital Label Printing, Online Label Printing Services'
    };
  }
  /* Properties */
  /* Store Actions */
  @Getter('displayName', { namespace: 'profile' })
  displayName!: string;
  @Action('logout', { namespace: 'profile' }) logout: any;
  /* Watchers */
  /* Data */
  shouldShowMoreDetails: boolean = true;

  contacts: Array<{
    imgSrc: string;
    title: string;
    subtitle: string;
    description: string;
    isLink: boolean;
  }> = [
    {
      imgSrc: 'emailicon.svg',
      title: 'EMAIL',
      subtitle: 'info@just1label.com',
      description: 'Contact us here for general inquiries or assistance.',
      isLink: true
    },
    {
      imgSrc: 'phoneicon.svg',
      title: 'PHONE',
      subtitle: '480.893.0005',
      description:
        "Call this number to talk to a real live human being, or leave us a message and we'll call you right back",
      isLink: false
    },
    {
      imgSrc: 'faxicon.svg',
      title: 'FAX',
      subtitle: "800.the.1980's",
      description: 'Please stop faxing',
      isLink: false
    }
  ];
  /* Utility Functions */
  goTo(name: string) {
    return this.$router.push({ name: name });
  }
  getDirections() {
    window.open(
      'https://www.google.com/maps?saddr=My+Location&daddr=6885+W+Frye+Rd,+Chandler,+AZ+85226',
      '_blank'
    );
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    if (this.$route.fullPath.includes('redirectFromTransparency')) {
      this.logout();
      return;
    }
    if (this.displayName && this.displayName !== 'No Value') this.$router.push('/items');
    if (!globalThis.google) {
      const plugin = document.createElement('script');
      plugin.setAttribute(
        'src',
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCf8dttldSSV_sltYdIugNhsTewVDj6NeA&callback=initMap&v=weekly'
      );
      document.head.appendChild(plugin);
    } else {
      setTimeout(() => {
        initMap();
      }, 300);
    }
  }
  /* Created */
  created() {
    if (this.displayName && this.displayName !== 'No Value') this.$router.push('/items');
  }
  /* Emmited Functions */
}
